import React, { useRef } from 'react';
import { NavLink } from "react-router-dom";
import './navbar.css';
import logo from "../../assets/images/logo.png";
import setting from "../../assets/images/settings.png";
import avatar from "../../assets/images/avatar.png";
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';
import { Ripple } from 'primereact/ripple';
import { useNavigate } from "react-router-dom";


const Navbar = () => {

  const toast = useRef(null);
  let navigate  = useNavigate();

const navigateTo = (url) =>{
    navigate(url);

}
  const items = [
      // {
      //     label: 'Change Password',
      //     command: () => {
      //         toast.current.show({ severity: 'Change Password', summary: 'Change Password', detail: 'Data Updated' });
      //       navigateTo('login');
      //     }
      // } ,
      {
        label: 'Logout',
        command: () => {
            toast.current.show({ severity: 'Logged Out', summary: 'Logged Out', detail: 'Thank you' });
            localStorage.removeItem('nameval');
          navigateTo('/login');
        }
    }          
  ];

//   const save = () => {
//     toast.current.show({ severity: 'success', summary: 'Success', detail: 'Data Saved' });
// };

  return (
    <>
 
   { window.location.pathname !=='/login' &&
    <div className="">
      <ul className="flex mainNav">
        <li>
          <NavLink to="/">
            <img src={logo} alt="" className="" />
          </NavLink>
        </li>
        <li>
        <div className="rightlogo">
          <ul className="flex">
            <li>
              <NavLink to="#"><img src={setting} alt="" className="" /></NavLink>
            </li>
            <li>
            <div className="userEditWrap">
            <Toast ref={toast}></Toast>
            <SplitButton label="Hi User" model={items} />
       
              {/* <span>Hi User </span>
              <i className="pi pi-angle-down" />
              <div className="PasswordWrap">
                  <span>Change Password</span>
              </div> */}
          </div>
            <NavLink to="/"><img src={avatar} alt="" className="" /></NavLink>
            </li>
          </ul>
          
      </div>
        </li>
      </ul>
    </div>}
    </>
  );
};

export default Navbar;
