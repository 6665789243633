import { createSlice } from "@reduxjs/toolkit";

const dropdownSlice = createSlice({
  name: "dropdown",
  initialState: [],
  reducers: {
    setIsCheck: (state, action) => {
      console.log(JSON.stringify(action.payload), state, "action from Origin")
      return action.payload;
    },
  },
});

export const { setIsCheck, deleteIsCheck } = dropdownSlice.actions;
export default dropdownSlice.reducer;