//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";     
    
//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";   
import AppRoutes from "./routes";
import "./globals.css";

function App() {
  return (
    <div className="App">
            <AppRoutes />
    </div>
  );
}

export default App;
