import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Message } from "primereact/message";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/mainlogo.png";
// import { CountryService } from '../service/CountryService';
import "./login.css";

export default function Login() {
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({});
  let navigate = useNavigate();

  const navigateTo = (url) => {
    navigate(url);
  };
  // const countryservice = new CountryService();
  const defaultValues = {
    mode: "onChange",
    email: "",
    password: "",
  };

  // useEffect(() => {
  //     countryservice.getCountries().then(data => setCountries(data));
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = async (userData) => {
    setFormData(userData);
    console.log(userData);
    // return
    var audience = await fetch(
      `${process.env.REACT_APP_CMS_URL}/web/pages/planning/plannerLogin.jsp?password=${userData.password}&user_id=${userData.email}`
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          setShowMessage(false);
          localStorage.setItem("nameval", userData.email);
          navigateTo("/home");
        } else {
          setTimeout(() => {
            setShowMessage(false);
          }, 5000);
          setShowMessage(true);
        }
      });

    // reset();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => setShowMessage(false)}
      />
    </div>
  );
  return (
    <div className="form">
      <div className="login-form">
        {/* <Toast ref={toast} /> */}

        <div className="logoWrap">
          <img src={logo} alt="" className="" />
        </div>
        {/* <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                    <div className=" justify-content-center flex-column pt-6 px-3">
                        <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                        <h5>Login Successful!</h5>
                        <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                            You have logged in Successfully<b>{formData.name}</b>
                        </p>
                    </div>
                </Dialog> */}

        <div className="flex justify-content-center">
          <div className="card">
            <h2 className="head-title">Welcome</h2>
            <p>Fill in your official Email ID and create a password.</p>
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <div className="field">
                <span className="p-float-label p-input-icon-right">
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required.",
                      pattern: {
                        value: "",
                        message:
                          "Invalid email address. E.g. example@email.com",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    htmlFor="email"
                    className={classNames({ "p-error": !!errors.email })}
                  >
                    Email ID
                  </label>
                </span>
                {getFormErrorMessage("email")}
              </div>
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: "Password is required." }}
                    render={({ field, fieldState }) => (
                      <Password
                        id={field.name}
                        {...field}
                        feedback={false}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        toggleMask
                      />
                    )}
                  />
                  <label
                    htmlFor="password"
                    className={classNames({ "p-error": errors.password })}
                  >
                    Password
                  </label>
                </span>
                {getFormErrorMessage("password")}
              </div>
              <Button
                type="submit"
                disabled={!isValid}
                label="LET'S GET STARTED"
                className="btn mb10"
              />
              {showMessage && (
                <Message severity="error" text="Enter Correct details" />
              )}
            </form>
            <p className="small">
              By signing in or creating an account, you agree with our
              <a href="javascript:void(0);"> Terms &amp; Conditions </a>
              and
              <a href="javascript:void(0);"> Privacy Statement </a>
            </p>
            <p className="alreadyregister">
              {" "}
              Already Registered? <a href="javascript:void(0);"> SIGN IN </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
