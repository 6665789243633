import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import "./dashboard.css";

const Dashboard = () => {
  const [data, setData] = React.useState([
    { page: "DataLayer Level Planning", url: "/create-audience" },
    { page: "Website Level Planning", url: "/websitePlanning" },
    { page: "Audience Level Planning", url: "/audiencePlanning" },
    // { page: 'google Keyword Search Volume' , url : '/test3' },
    // { page: 'Facebook Audience Planning' , url : '/test4' },
  ]);

  const [websiteData, setWebsiteData] = useState("");
  const [audienceData, setAudienceData] = useState("");

  const actionTemplate = (rowData = data) => {
    // data.map((data)=>{

    // })
    return (
      <a className="btn outline-secondary d-block d30 ptb05" href={rowData.url}>
        Create
      </a>
    );
  };

  useEffect(() => {}, []);

  const isLocal = localStorage.getItem("nameval");

  return (
    <>
      {isLocal && (
        <div className="dashboard">
          <div class="container ptb4">
            <div className="viewadsbtn audienceBtn">
              <h3>Dashboard</h3>
            </div>
            <div class="dashboard-table">
              <DataTable value={data}>
                <Column field="page" header="Page" />
                <Column body={actionTemplate} header="Action" />
              </DataTable>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
