import React from "react";
import { Link } from "react-router-dom";
import { CiSettings } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { BsZoomIn } from "react-icons/bs";
import { MdZoomOutMap } from "react-icons/md";

export default function page() {
  return (
    <>
      <hr className="mtb4" />
      <div className="container">
        <h1 className="h1">This is h1</h1>
        <h2 className="h2">This is h2</h2>
        <h3 className="h3">This is h3</h3>
        <h4 className="h4">This is h4</h4>
        <h5 className="h5">This is h5</h5>
        <h6 className="h6">This is h6</h6>
        <p>This is paragraph</p>
        <p className="small">This is paragraph small</p>
        <p className="xsmall">This is paragraph extra small</p>
        <hr className="d80" />
        <p>Small buttons</p>
        <Link className="btn small">Primary</Link>
        <Link className="btn secondary small">Secondary</Link>
        <Link className="btn outline small">Outline</Link>
        <Link className="btn outline-secondary small">Outline</Link>
        <hr className="d40" />
        <p>Regular buttons</p>
        <Link className="btn">Primary</Link>
        <Link className="btn secondary">Secondary</Link>
        <Link className="btn outline">Outline</Link>
        <Link className="btn outline-secondary">Outline</Link>
        <hr className="d40" />
        <p>Large buttons</p>
        <Link className="btn large">Primary</Link>
        <Link className="btn secondary large">Secondary</Link>
        <Link className="btn outline large">Outline</Link>
        <Link className="btn outline-secondary large">Outline</Link>
        <hr className="d80" />
        <p>Regular Checkboxes</p>
        <input className="checkbx" type="checkbox" />
        <input className="checkbx secondary" type="checkbox" />
        <hr className="d40" />
        <p>Medium Checkboxes</p>
        <input className="checkbx medium" type="checkbox" />
        <input className="checkbx secondary medium" type="checkbox" />
        <hr className="d40" />
        <p>Large Checkboxes</p>
        <input className="checkbx large" type="checkbox" />
        <input className="checkbx secondary large" type="checkbox" />
        <hr className="d80" />
        <p>Icons</p>
        <CiSettings className="h2 mr2 primary-font" />
        <MdDeleteOutline className="h2 mr2 primary-font" />
        <BsZoomIn className="h3 mr2 primary-font" />
        <MdZoomOutMap className="h3 mr2 primary-font" />
        <hr className="d80" />
      </div>
    </>
  );
}
