import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
// import "./websitePlanning.css";
import { useLocation } from "react-router-dom";
import { Toast } from "primereact/toast";
import Axios from "axios";
import { uuid } from "uuidv4";

// const dummyData = [
//   { age: "", gender: "", state: "", website: "" },
//   // Add more dummy data here as needed
// ];

const App = () => {
  const [showAge, setShowAge] = useState(false);
  const [showGender, setShowGender] = useState(false);
  const [showWebsite, setShowWebsite] = useState(false);
  const [showSection, setShowSection] = useState(false);
  const [showStates, setShowStates] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const toast = useRef(null);

  // const [queryParams, setQueryParams] = useState("");
  let queryParams = "";

  const [selectedGender, setSelectedGender] = useState("");
  //const [selectedAge, setSelectedAge] = useState("All Age Group");
  const [selectedAge, setSelectedAge] = useState("");
  const [selectedWebsite, setSelectedWebsite] = useState("");
  const [selectedSection, setSelectedSection] = useState("All section");
  const [selectedStates, setSelectedStates] = useState("");
  const [selectedCampaignDuration, setSelectedCampaignDuration] = useState("");
  const [selectedCampaignBudget, setSelectedCampaignBudget] = useState("");

  const [websiteData, setWebsiteData] = useState("");
  const [audienceData, setAudienceData] = useState("");

  const [selectedRows, setSelectedRows] = useState();

  const onShow = () => {
    let selectAllCheckbox = document.querySelector(
      ".p-multiselect-header > .p-checkbox"
    );
    selectAllCheckbox.after("All Gender");
  };

  const [campaignPlanObj, setCampaignPlanObj] = useState({
    campaignName: "",
    noOfDays: "",
    objective: "",
    budget: "",
  });

  const [isFormComplete, setIsFormComplete] = useState(false);

  const inputOnChange = (e) => {
    setCampaignPlanObj({ ...campaignPlanObj, [e.target.name]: e.target.value });

    // Check if all fields in campaignPlanObj are filled
    const allFieldsFilled = Object.values(campaignPlanObj).every(
      (value) => value !== ""
    );
    setIsFormComplete(allFieldsFilled);
  };

  const OnSubmit = async () => {
    // console.log(campaignPlanObj, tableData, 'test');
    tableData.forEach((item) => {
      item.type = "website";
    });
    console.log(campaignPlanObj, tableData, "test");

    // return
    // const TableData = Array.isArray(selectedRows) ?
    // selectedRows.map((a) => {
    //   return {
    //     type: "website",
    //     ageRange: a.age,
    //     gender: a.gender,
    //     geo: a.state,
    //     webiste: a.website,
    //     affinity: ""
    //   };
    // }) : [];
    campaignPlanObj.referenceNo = uuid(new Date());

    const Compainobj = {
      campaignPlanDetails: tableData,
      campaignPlan: campaignPlanObj,
    };
    console.log(Compainobj, "CompainData");
    console.log(selectedRows);

    let data = JSON.stringify(Compainobj); // Corrected the variable name to Compainobj
    //console.log("saved", Compainobj);

    Axios.post(
      `${process.env.REACT_APP_CMS_URL}/web/pages/planning/plannerCampaignDetailsSubmit.jsp`,
      data
    )
      .then((res) => {
        console.log("abccal", res.data);
        if (res?.data) {
          toast.current.show({
            severity: "success",
            summary: "Success Message",
            detail: "Plan Saved Successfully",
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      })
      .catch((err) => console.log(err));
  }; // Removed the extra closing brace here

  const onSelectionChange = (e) => {
    setSelectedRows(e.value);
    //console.log(e.value);
  };

  const handleRowSelect = (filteredData) => {
    // Log the selected row data to the console
    //console.log('Selected Row:', filteredData);
  };

  const location = useLocation();
  const path = location.pathname;
  // const queryParams = new URLSearchParams(location.search);
  // queryParams.append("name", "true");
  // console.log(queryParams, 'query');
  // console.log(path)
  useEffect(() => {
    getData(path);
  }, [path]);

  const addQueryParams = (type, val) => {
    const searchParams = new URLSearchParams();
    if (showAge) {
      searchParams.append("age_group_check", true);
    }

    if (showGender) {
      searchParams.append("gender_check", true);
    }

    if (showStates) {
      searchParams.append("state_check", true);
    }

    if (showWebsite) {
      searchParams.append("website_check", true);
    }

    console.log(searchParams.toString());
    queryParams = "&" + searchParams.toString();
    console.log(queryParams);
  };

  function convertData(inputData) {
    const result = {};

    inputData.forEach((item) => {
      const key = item.behaviorType;
      const values = item.data.map((behavior) => behavior.behaviorName);
      console.log(
        values,
        "values",
        item.data.map((item) => item.behaviorName)
      );
      result[key] = values;
    });

    return result;
  }
  const getData = async (type) => {
    if (type === "/websitePlanning") {
      var audience = await fetch(
        `${process.env.REACT_APP_CMS_URL}/web/pages/planning/plannerInputDropdownResponse.jsp?type=website`
      )
        .then((res) => res.json())
        .then((data) => {
          setAudienceData(data);
          console.log(data);
        });
    } else {
      var audience = await fetch(
        `${process.env.REACT_APP_CMS_URL}/web/pages/planning/getLotameBehaviors.jsp`
      )
        .then((res) => res.json())
        .then((data) => {
          setAudienceData(data);
          console.log(data, "abctest");
          const convertedData = convertData(data);
          console.log(convertedData, "convertedData");
        });
    }
    // return
  };

  const getTableDataWebsite = async () => {
    var tableData = await fetch(
      `${process.env.REACT_APP_CMS_URL}/web/pages/planning/plannerInputWebsiteResponse.jsp?age_group=` +
        selectedAge +
        "&website=" +
        selectedWebsite +
        "&state=" +
        selectedStates +
        "&gender=" +
        selectedGender +
        "" +
        "&campaign_duration=" +
        campaignPlanObj.noOfDays +
        "" +
        "&campaign_budget=" +
        campaignPlanObj.budget +
        "" +
        queryParams +
        ""
    )
      .then((res) => res.json())
      .then((data) => {
        setFilteredData(data);
        setTableData(data);
      });
  };
  const getTableDataSection = async () => {
    //console.log(selectedAge.toString(), 'abc');
    var tableData = await fetch(
      `${process.env.REACT_APP_CMS_URL}/web/pages/planning/getLotameUniqueness.jsp?age_group=` +
        selectedAge.toString() +
        "&section=" +
        selectedSection.toString() +
        "&state=" +
        selectedStates.toString() +
        "&gender=" +
        selectedGender.toString() +
        ""
    )
      .then((res) => res.json())
      .then((data) => {
        setFilteredData(data);
        //console.log(data);
      });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(filteredData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "products");
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="container inner-page-site ptb4">
        <div className="viewadsbtn audienceBtn">
          <h3>Website Planning</h3>
        </div>
        <div className="row">
          <MultiSelect
            value={selectedAge}
            options={audienceData?.age_group}
            onChange={(e) => setSelectedAge(e.value)}
            placeholder="Select Age"
            optionLabel=""
            multiple
            className="dropdown"
            display="chip"
            maxSelectedLabels={3}
          />

          <MultiSelect
            value={selectedGender}
            options={audienceData?.gender}
            onChange={(e) => setSelectedGender(e.value)}
            placeholder="Select Gender"
            optionLabel=""
            multiple
            className="dropdown"
            display="chip"
            maxSelectedLabels={3}
            onShow={onShow}
          />

          <MultiSelect
            options={audienceData?.state}
            value={selectedStates}
            onChange={(e) => setSelectedStates(e.value)}
            placeholder="Select State"
            optionLabel=""
            className="dropdown"
            multiple
            display="chip"
            maxSelectedLabels={3}
          />

          {path === "/websitePlanning" ? (
            <MultiSelect
              value={selectedWebsite}
              options={audienceData?.website}
              display="chip"
              maxSelectedLabels={3}
              onChange={(e) => {
                setSelectedWebsite(e.value);
                addQueryParams();
                getTableDataWebsite();
              }}
              placeholder="Select Website"
              optionLabel=""
              className="dropdown"
            />
          ) : (
            path
          )}
        </div>
        <div className="row pt2">
          <div className="checkbox-block">
            <label>Age</label>
            <input
              type="checkbox"
              checked={showAge}
              onChange={(e) => {
                setShowAge(e.checked);
              }}
              className="checkbx"
            />
          </div>
          <div className="checkbox-block">
            <label>Gender</label>
            <input
              className="checkbx"
              type="checkbox"
              checked={showGender}
              onChange={(e) => setShowGender(e.checked)}
            />
          </div>
          {/* State Checkbox */}
          <div className="checkbox-block">
            <label>State</label>
            <input
              className="checkbx"
              type="checkbox"
              checked={showStates}
              onChange={(e) => setShowStates(e.checked)}
            />
          </div>

          {/* Website Checkbox */}
          {path === "/websitePlanning" ? (
            <div className="checkbox-block">
              <label>Website</label>
              <input
                className="checkbx"
                type="checkbox"
                checked={showWebsite}
                onChange={(e) => {
                  setShowWebsite(e.checked);
                  addQueryParams();
                  getTableDataWebsite();
                }}
              />
            </div>
          ) : (
            <div className="checkbox-block">
              <label>Section</label>
              <Checkbox
                checked={showSection}
                onChange={(e) => {
                  setShowSection(e.checked);
                }}
                className="checkbox"
              />
            </div>
          )}
        </div>

        <div className="row pt2">
          <div className="inputbox">
            <input
              type="text"
              className="newAudDetailInput planningInputs pl2"
              onChange={inputOnChange}
              // value={campaignPlanObj.noOfDays}
              placeholder="noOfDays"
            />
          </div>
          <div className="inputbox">
            <input
              type="text"
              className="newAudDetailInput planningInputs pl2"
              onChange={inputOnChange}
              // value={campaignPlanObj.budget}
              placeholder="budget"
            />
          </div>
        </div>

        {path === "/websitePlanning" ? (
          <div className="row">
            <button
              onClick={() => {
                addQueryParams();
                setTimeout(() => {
                  getTableDataWebsite();
                }, 1000);
              }}
              className="btn"
            >
              Calculate
            </button>
          </div>
        ) : (
          <div className="row">
            <button onClick={() => getTableDataSection()} className="btn">
              Calculate
            </button>
          </div>
        )}

        <div className="table-container">
          {filteredData.length > 0 && (
            <DataTable
              value={filteredData}
              paginator
              responsiveLayout="scroll"
              scrollable
              scrollHeight="400px"
              selectionMode="multiple"
              selection={selectedRows}
              data={filteredData}
              onRowSelect={handleRowSelect}
              onSelectionChange={onSelectionChange}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rows={7}
              rowsPerPageOptions={[10, 20, 50]}
            >
              <Column selectionMode="multiple"></Column>
              <Column field="age" header="Age" sortable />
              <Column field="gender" header="Gender" />
              <Column field="state" header="State" sortable />
              <Column field="website" header="website" sortable />
              {showWebsite || selectedWebsite != "All website" ? (
                <Column field="trai_reach" header="APT Reach" sortable />
              ) : null}
              {showWebsite || selectedWebsite != "All website" ? (
                <Column
                  field="avg_min_visitor"
                  header="Average Minutes"
                  sortable
                />
              ) : null}
              {showWebsite || selectedWebsite != "All website" ? (
                <Column
                  field="comosition_index_uv"
                  header="Composition Index"
                  sortable
                />
              ) : null}
            </DataTable>
          )}
        </div>

        <div className="row">
          <div className="inputbox">
            <input
              type="text"
              onChange={inputOnChange}
              className="newAudDetailInput planningInputs pl2"
              placeholder="campaignName"
            />
          </div>
          {/* <InputText type="text" onChange={inputOnChange} value={campaignPlanObj.noOfDays} name="noOfDays" placeholder="noOfDays"/> */}
          <div className="inputbox">
            <input
              type="text"
              onChange={inputOnChange}
              className="newAudDetailInput planningInputs pl2"
              placeholder="objective"
            />
          </div>
        </div>

        <div className="row">
          <button onClick={OnSubmit} className="btn" disabled={!isFormComplete}>
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default App;
