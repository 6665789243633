import React, { useState, useEffect } from "react";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { Slider } from "primereact/slider";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
// import "./home.css";
import { GlobalService } from "../../service/globalService";
import { useNavigate, Navigate } from "react-router-dom";
import { Ripple } from "primereact/ripple";
import { IoMdArrowDown } from "react-icons/io";
import { PiDotsThreeOutline } from "react-icons/pi";
import { LuRefreshCw } from "react-icons/lu";
import { CiFilter } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import { Paginator } from "primereact/paginator";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";

import Axios from "axios";

export default function FilterMenuDoc() {
  const [customers, setCustomers] = useState(null);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10); // Default to 10 rows
  const representatives = [
    { name: "Amy Elsner", image: "amyelsner.png" },
    { name: "Anna Fali", image: "annafali.png" },
    { name: "Asiya Javayant", image: "asiyajavayant.png" },
    { name: "Bernardo Dominic", image: "bernardodominic.png" },
    { name: "Elwin Sharvill", image: "elwinsharvill.png" },
    { name: "Ioni Bowcher", image: "ionibowcher.png" },
    { name: "Ivan Magalhaes", image: "ivanmagalhaes.png" },
    { name: "Onyama Limba", image: "onyamalimba.png" },
    { name: "Stephen Shaw", image: "stephenshaw.png" },
    { name: "XuXue Feng", image: "xuxuefeng.png" },
  ];

  const statuses = [
    "unqualified",
    "qualified",
    "new",
    "negotiation",
    "renewal",
    "proposal",
  ];

  const paginatorLeft = (
    <Button type="button" className="">
      Previous{" "}
    </Button>
  );
  const paginatorRight = (
    <Button type="button" className="">
      Next{" "}
    </Button>
  );

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_CMS_URL}/web/pages/planning/getLotameCustomAudience.jsp`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    )
      .then((res) => {
        setCustomers(getCustomers(res.data));
        setLoading(false);
      })
      .catch((err) => console.log(err));

    // GlobalService.getCustomersLarge().then((data) => {
    //     setCustomers(getCustomers(data));
    //     setLoading(false);
    // });
    initFilters();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomers = (data) => {
    return [...(data || [])].map((d) => {
      d.lastModifiedDate = new Date(d.lastModifiedDate);
      return d;
    });
  };

  const formatDate = (value) => {
    return value.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  // const formatCurrency = (value) => {
  //     return value.toLocaleString("en-US", {
  //         style: "currency",
  //         currency: "USD",
  //     });
  // };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "country.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      representative: { value: null, matchMode: FilterMatchMode.IN },
      lastModifiedDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      balance: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      status: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
      verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  };

  const renderHeader = () => {
    return (
      <>
        <div className="flex gc-sb items-center">
          <input
            type="search"
            className="newAudDetailInput planningInputs pl2"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
          <div className="flex items-center gc-c">
            {/* <Button
              type="button"
              icon="pi pi-replay"
              className="p-button-outlined"
              onClick={clearFilter}
              title="Clear Table Filters"
            /> */}
            <LuRefreshCw className="h6 mb0" onClick={clearFilter} />
            {/* <IoMdArrowDown className="h6 mb0" /> */}
            {/* <PiDotsThreeOutline className="h6 mb0" /> */}
          </div>
        </div>
        {/* <div className="flex gc-start items-center pt2 ptb1">
          <CiFilter className="h6 mb0" />
          <span className="flex">
            <FaPlus className="h6 mb0" />
            Filter
          </span>
        </div> */}
      </>
    );
  };

  const countryBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="vertical-align-middle ml-2">
          {rowData.audienceSize}
        </span>
      </React.Fragment>
    );
  };

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={options.filterClearCallback}
        className="p-button-secondary"
      ></Button>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={options.filterApplyCallback}
        className="p-button-success"
      ></Button>
    );
  };

  const filterFooterTemplate = () => {
    return (
      <div className="px-3 pt-0 pb-3 text-center font-bold">
        Customized Buttons
      </div>
    );
  };

  const representativeBodyTemplate = (rowData) => {
    const representative = rowData.representative;
    return (
      <React.Fragment>
        <span className="vertical-align-middle ml-2">
          {representative.name}
        </span>
      </React.Fragment>
    );
  };

  const representativeFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={representatives}
        itemTemplate={representativesItemTemplate}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="name"
        placeholder="Any"
        className="p-column-filter"
      />
    );
  };

  const representativesItemTemplate = (option) => {
    return (
      <div className="p-multiselect-representative-option">
        <img
          alt={option.name}
          src={`https://primefaces.org/cdn/primereact/images/avatar/${option.image}`}
          width={32}
          style={{ verticalAlign: "middle" }}
        />
        <span className="vertical-align-middle ml-2">{option.name}</span>
      </div>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.lastModifiedDate);
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const balanceBodyTemplate = (rowData) => {
    // return formatCurrency(rowData.balance);
  };

  const balanceFilterTemplate = (options) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        mode="currency"
        currency="USD"
        locale="en-US"
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <span className={`customer-badge status-${rowData.status}`}>
        {rowData.status}
      </span>
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };

  const activityBodyTemplate = (rowData) => {
    return (
      <ProgressBar value={rowData.activity} showValue={false}></ProgressBar>
    );
  };

  const activityFilterTemplate = (options) => {
    return (
      <React.Fragment>
        <Slider
          value={options.value}
          onChange={(e) => options.filterCallback(e.value)}
          range
          className="m-3"
        ></Slider>
        <div className="flex align-items-center justify-content-between px-2">
          <span>{options.value ? options.value[0] : 0}</span>
          <span>{options.value ? options.value[1] : 100}</span>
        </div>
      </React.Fragment>
    );
  };

  const verifiedBodyTemplate = (rowData) => {
    return (
      // <i
      //     className={classNames("pi", {
      //         "true-icon pi-check-circle": rowData.verified,
      //         "false-icon pi-times-circle": !rowData.verified,
      //     })}
      // ></i>
      <>
        <Button icon="pi pi-pencil"></Button>
        <Button icon="pi pi-trash"></Button>
      </>
    );
  };

  const verifiedFilterTemplate = (options) => {
    return (
      <TriStateCheckbox
        value={options.value}
        onChange={(e) => options.filterCallback(e.value)}
      />
    );
  };

  const header = renderHeader();
  let navigate = useNavigate();

  const navigateTo = () => {
    navigate("/dashboard");
    <Navigate to={`/dashboard`} />;
  };

  const template1 = {
    layout:
      "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="pi pi-angle-left"></span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="pi pi-angle-right"></span>
          <Ripple />
        </button>
      );
    },
  };
  const isLocal = localStorage.getItem("nameval");
  const rowsPerPageOptions = [10, 20, 50];

  const customPaginator = (options) => (
    <div className="flex pageinator-Container">
      {/* Rows Per Page Dropdown */ console.log(options, "options")}
      <Dropdown
        value={options.rows}
        options={rowsPerPageOptions} // Use rowsPerPageOptions here
        // onChange={options.onPageChange}
        onChange={(e) => {
          setRows(e.value); // Update the number of rows
          setFirst(0); // Reset to the first page when rows per page changes
        }}
        className="p-ml-2"
        placeholder="Rows per page"
      />
      <span className="p-mr-auto p-ml-auto">
        {options.first} of {options.totalRecords}
      </span>

      <IoIosArrowRoundBack className="h4 pb0 paginationArrows" />
      <IoIosArrowRoundForward className="h4 pb0" />
    </div>
  );

  return (
    <>
      {isLocal && (
        <>
          <div className="container ptb4">
            <div className="viewadsbtn audienceBtn flex gc-sb items-center mb10">
              <h3 className="mb0">Audience List</h3>
              <Button className="btn outline" onClick={navigateTo}>
                {" "}
                Create Audience
              </Button>
            </div>
            <div>
              <div>
                <DataTable
                  value={customers}
                  stripedRows
                  paginator
                  paginatorLeft={null}
                  paginatorRight={customPaginator}
                  rows={rows} // Rows per page
                  first={first} // Current starting point
                  onPage={(e) => setFirst(e.first)} // Update the starting point when the page changes
                  totalRecords={23} // Total number of records
                  className="p-datatable-customers"
                  showGridlines
                  dataKey="id"
                  filters={filters}
                  filterDisplay="menu"
                  loading={loading}
                  responsiveLayout="scroll"
                  // sortMode="multiple"
                  // paginatorTemplate={template1}
                  // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  // currentPageReportTemplate="{first} of {totalRecords}"
                  // rowsPerPageOptions={rowsPerPageOptions}
                  // rowsPerPageOptions={rowsPerPageOptions} // Pass rowsPerPageOptions here as well
                  //paginatorTemplate={customPaginator} // Custom paginator without page numbers
                  // paginatorLeft={null}
                  // paginatorRight={customPaginator}
                  globalFilterFields={[
                    "campaignName",
                    "type",
                    "objective",
                    "budget",
                    "noOfDays",
                  ]}
                  header={header}
                  emptyMessage="No customers found."
                >
                  <Column
                    field="campaignName"
                    header="Name"
                    filterPlaceholder="Search by name"
                    sortable
                  />
                  {/* <Column
                            header="Audience Size"
                            field="audienceSize"
                            // filterField=""
                            body={countryBodyTemplate}
                            sortable
                        /> */}
                  <Column
                    header="Type"
                    field="type"
                    filterMenuStyle={{ width: "10rem" }}
                    // body={representativeBodyTemplate}
                    // sortable
                  />
                  {/* <Column
                            header="Updated Date"
                            // field="lastModifiedDate"
                            filterField="lastModifiedDate"
                            dataType="date"
                            body={dateBodyTemplate}
                            filter
                            filterElement={dateFilterTemplate}
                        /> */}
                  <Column
                    header="Objective"
                    field="objective"
                    dataType="numeric"
                    // body={balanceBodyTemplate}
                  />
                  <Column
                    header="Budget"
                    field="budget"
                    dataType="numeric"
                    sortable
                    // body={balanceBodyTemplate}
                  />
                  <Column
                    header="Duration"
                    field="noOfDays"
                    dataType="numeric"
                    sortable
                    // body={balanceBodyTemplate}
                  />
                  <Column
                    field="Actions"
                    header="Actions"
                    dataType="boolean"
                    bodyClassName="text-center"
                    body={verifiedBodyTemplate}
                  />
                </DataTable>
                {/* <Paginator first={first} rows={rows} totalRecords={120} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} /> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
